<template>
  <section class="text-page">
    <div class="inner">
      <template v-if="$store.getters.LANG === 'ua'">
        <h1>Умови використання сайту</h1>
        <p>Увага! Перед переглядом цього сайту уважно прочитайте ці умови. Якщо ви не згодні з цими умовами, не використовуйте цей сайт.</p>
        <br><br>
        <h3>Використання сайту</h3>
        <p>Сайт kolomafamily.com (далі «Koloma Family») дозволяє вам переглядати та завантажувати матеріали цього сайту (далі «Сайт») тільки для особистого некомерційного використання, за умови збереження вами всієї інформації про авторське право та інших відомостей про право власності, що містяться у вихідних матеріалах і будь-яких їхніх копіях. Заборонено змінювати матеріали цього Сайту, а також поширювати або демонструвати їх у будь-якому вигляді або використовувати їх будь-яким іншим чином для суспільних чи комерційних цілей. Будь-яке використання цих матеріалів на інших сайтах або в комп'ютерних мережах заборонено.</p>
        <br><br>
        <h3>Відмова від відповідальності</h3>
        <p>Матеріали та послуги цього сайту надаються «як є» без жодних гарантій. «Koloma Family» не гарантує точності та повноти матеріалів, програм і послуг, що надаються на цьому Сайті. «Koloma Family» в будь-який час без повідомлення може вносити зміни в матеріали та послуги, що надаються на цьому Сайті, а також у згадані в них продукти та ціни. У разі, якщо матеріали та послуги на цьому Сайті застаріють, «Koloma Family» не зобов'язана оновлювати їх. «Koloma Family» за жодних обставин не несе відповідальності за будь-які збитки (включно з, але не обмежуючись збитком від втрати прибутку, даних або від переривання ділової активності), що виникли внаслідок використання, неможливості використання або результатів використання цього сайту.</p>
        <br><br>
        <h3>Зворотний зв'язок і коментарі</h3>
        <p>Звертаючись до нас або залишаючи коментарі на сайті, ви несете відповідальність, що це повідомлення не є незаконним, шкідливим, загрозливим, наклепницьким, суперечить моральним нормам, порушує авторські права, пропагує ненависть і/або дискримінацію людей за расовою, етнічною, статевою, релігійною, соціальною ознаками, містить образи на адресу конкретних осіб або організацій, а також будь-яким іншим чином порушує чинне законодавство України. Ви погоджуєтеся, що будь-яке ваше повідомлення «Koloma Family» може видаляти без вашої на те згоди, а також безоплатно використовувати на власний розсуд. «Koloma Family» не несе відповідальності за будь-яку інформацію, розміщену користувачами Сайту.</p>
        <br><br>
        <h3>Використання персональних даних</h3>
        <p>Ми використовуємо різні технології для збирання та зберігання інформації, коли ви відвідуєте сайт «Koloma Family». Це може включати в себе запис одного або декількох кукі або анонімних ідентифікаторів. Ми також використовуємо кукі й анонімні ідентифікатори, коли ви взаємодієте з послугами, запропонованими нашими партнерами, як-от рекламні послуги, наприклад, що можуть з'явитися на інших сайтах.</p>
      </template>
      <template v-else>
        <h1>Terms of use of the site</h1>
        <p>WARNING! Please read these terms carefully before viewing this site. If you do not agree to these terms, do not use this site.</p>
        <br><br>
        <h3>Use of the site</h3>
        <p>Site kolomafamily.com ("Koloma Family") permits you to view and download materials from this site ("Site") for your personal, non-commercial use only, provided that you retain all copyright and other proprietary notices contained in source materials and any copies thereof. It is forbidden to modify the materials of this Site, as well as to distribute or display them in any form or to use them in any other way for public or commercial purposes. Any use of these materials on other sites or in computer networks is prohibited.</p>
        <br><br>
        <h3>Disclaimer</h3>
        <p>The materials and services on this site are provided "as is" without warranty of any kind. Koloma Family does not guarantee the accuracy and completeness of the materials, programs and services provided on this Site. Koloma Family may at any time without notice make changes to the materials and services provided on this Site, as well as to the products and prices mentioned therein. If the materials and services on this Site become outdated, Koloma Family is not obligated to update them. In no event shall Koloma Family be liable for any damages (including, but not limited to, loss of profits, data or business interruption) arising from the use, inability to use or the results of the use of this site.</p>
        <br><br>
        <h3>Feedback and comments</h3>
        <p>By contacting us or leaving comments on the site, you are responsible that this message is not illegal, harmful, threatening, defamatory, contrary to moral standards, violates copyright, promotes hatred and/or discrimination against people based on race, ethnicity, sex, religion, social signs, contains insults to specific persons or organizations, as well as in any other way violates the current legislation of Ukraine. You agree that any of your communications may be removed by Koloma Family without your consent and may be used by Koloma Family at its sole discretion. Koloma Family is not responsible for any information posted by users of the Site.</p>
        <br><br>
        <h3>Use of personal data</h3>
        <p>We use various technologies to collect and store information when you visit the Koloma Family website. This may include recording one or more cookies or anonymous identifiers. We also use cookies and anonymous identifiers when you interact with services offered by our partners, such as advertising services that may appear on other sites.</p>
      </template>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Terms"
})
</script>

<style scoped lang="scss" src="../assets/scss/policy_terms.scss"></style>
